var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AbstractService } from './abstract-service';
var IVerifyTokenResponse = /** @class */ (function () {
    function IVerifyTokenResponse() {
    }
    return IVerifyTokenResponse;
}());
export { IVerifyTokenResponse };
var RegistrationPasswordService = /** @class */ (function (_super) {
    __extends(RegistrationPasswordService, _super);
    function RegistrationPasswordService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.API_URL = 'api/register';
        return _this;
    }
    RegistrationPasswordService.prototype.verifyToken = function (code) {
        return this.http.get(this.API_URL + "/verify-token", {
            params: {
                code: code
            }
        }).pipe(map(function (response) { return response; }));
    };
    RegistrationPasswordService.prototype.savePassword = function (email, password, code) {
        return this.http.post(this.API_URL + "/password", { email: email, password: password, token: code }, { headers: this.buildRequestHeaders() });
    };
    return RegistrationPasswordService;
}(AbstractService));
export { RegistrationPasswordService };
