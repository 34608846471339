import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {HeaderModule} from '../header/header.module';
import {MaterialModule} from '../material.module';
import {SharedModule} from '../shared.module';
import {EPassComponent} from './main/epass.component';

export const routes: Routes = [
  {path: '', component: EPassComponent},
];

@NgModule({
  declarations: [
    EPassComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    SharedModule,
    HeaderModule
  ],
  exports: [
    EPassComponent
  ],
  providers: [
  ],
  entryComponents: [
    EPassComponent
  ]
})
export class EPassModule {
}
