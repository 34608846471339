<header class="header">
  <div class="header__menu">
    <app-menu></app-menu>
  </div>
  <div class="header__lang">
    <img *ngIf="!isEpassView" src="assets/enjoy_logo.png" alt="Enjoy logo">
  </div>
  <div class="header__logo">
    <img src="assets/Roche3.png" alt="Roche logo">
  </div>
</header>
