import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';

import {UserService} from '../shared/services/user.service';

@Injectable()
export class IsActiveUserGaurd implements CanActivate {

  constructor(private router: Router, private userService: UserService) {}

  canActivate(): Promise<boolean> {
    const navigateToHome = () => this.router.navigate(['']);

    return new Promise((resolve) => {
      this.userService.getLoggedUser({dontFetch: true})
        .pipe(
          map((user) => {
            if (user.isActive) {
              resolve(true);
            } else {
              navigateToHome();
              resolve(false);
            }
          }),
          catchError((err, caught) => {
            navigateToHome();
            resolve(false);
            return null;
          })
        ).subscribe();
    });
  }
}
