import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material';

import {HeaderModule} from '../header/header.module';
import {SharedModule} from '../shared.module';
import {ErrorComponent} from './error.component';

@NgModule({
    declarations: [
        ErrorComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        SharedModule,
        HeaderModule
    ],
    exports: [
        ErrorComponent
    ],
    providers: [
    ],
    entryComponents: [
        ErrorComponent
    ]
})
export class ErrorModule {
}
