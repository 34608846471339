import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, map, share} from 'rxjs/operators';

export interface ISettings {
  offlineExpiryPeriodDays: number;
}

@Injectable()
export class SettingsService {
  readonly API_URL = 'api/settings';

  private settings: ISettings;
  private observable: Observable<ISettings>;

  constructor(private http: HttpClient) {
  }

  getSettings(): Observable<ISettings> {
    if (this.settings) {
      return of(this.settings);
    } else if (this.observable) {
      return this.observable;
    } else {
      this.observable = this.fetchSettings();
      return this.observable;
    }
  }

  private fetchSettings(): Observable<ISettings> {
    return this.http.get(this.API_URL)
      .pipe(
        map(response => {
          this.observable = null;
          return response as ISettings;
        }),
        catchError((err) => {
          console.error(err);
          this.observable = null;
          return EMPTY;
        }),
        share()
      );
  }
}
