import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ErrorComponent} from './error/error.component';
import {ErrorModule} from './error/error.module';
import {HomeComponent} from './home.component';
import {IsActiveUserGaurd} from './interceptors/is-active-user.gaurd';

export const appRoutes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'error/:message', component: ErrorComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'epass', loadChildren: './epass/epass.module#EPassModule', canActivate: [IsActiveUserGaurd]},
    {path: 'register', loadChildren: './register/register.module#RegisterModule'},
    {path: 'login', loadChildren: './login/login.module#LoginModule'},
    {path: 'password', loadChildren: './password/password.module#PasswordModule'},
    {path: 'update-profile', loadChildren: './update-profile/update-profile.module#UpdateProfileModule'}
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {enableTracing: false},
        ),
        ErrorModule
    ],
    exports: [
        RouterModule
    ],
    providers: [
        IsActiveUserGaurd
    ]
})
export class AppRoutingModule {
}
