import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBar,
  MatProgressBarModule,
  MatSnackBarModule,
  MatExpansionModule
} from '@angular/material';
import {MatMomentDateModule} from '@angular/material-moment-adapter';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatMomentDateModule,
    MatMenuModule,
    MatProgressBarModule,
    MatExpansionModule
  ],
  exports: [
    CommonModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatInputModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatCheckboxModule,
    MatMomentDateModule,
    MatMenuModule,
    MatProgressBar,
    MatExpansionModule
  ]
})
export class MaterialModule {
}
