import {APP_BASE_HREF, PlatformLocation} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {Notifier} from './components/notifier/notifier';
import {NotifierComponent} from './components/notifier/notifier.component';
import {DatePickerModule} from './date-picker.module';
import {EPassModule} from './epass/epass.module';
import {ErrorModule} from './error/error.module';
import {HeaderModule} from './header/header.module';
import {HomeComponent} from './home.component';
import {AuthenticationInterceptor} from './interceptors/authentication/authentication.interceptor';
import {ProgressInterceptor} from './interceptors/progress/progress.interceptor';
import {LoginModule} from './login/login.module';
import {MaterialModule} from './material.module';
import {MenuModule} from './menu/menu.module';
import {AppRoutingModule} from './routing.module';
import {BasePathInterceptor} from './shared/services/base-path.interceptor';
import {Language, LanguageService} from './shared/services/language.service';
import {ProgressService} from './shared/services/progress.service';
import {SettingsService} from './shared/services/settings.service';
import {TranslateService} from './shared/services/translate.service';
import {UserService} from './shared/services/user.service';

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use(Language.de);
}

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotifierComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    LoginModule,
    EPassModule,
    ErrorModule,
    HeaderModule,
    MenuModule,
    DatePickerModule
  ],
  entryComponents: [
    NotifierComponent
  ],
  providers: [
    Notifier,
    UserService,
    SettingsService,
    LanguageService,
    TranslateService,
    ProgressService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasePathInterceptor,
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
