var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Notifier } from '../../components/notifier/notifier';
import { AbstractService } from './abstract-service';
import { TranslateService } from './translate.service';
import { UserService } from './user.service';
export var Language;
(function (Language) {
    Language["de"] = "de";
    Language["en"] = "en";
})(Language || (Language = {}));
var UpdateLanguageRequest = /** @class */ (function () {
    function UpdateLanguageRequest(language) {
        this.language = language;
    }
    return UpdateLanguageRequest;
}());
var LanguageService = /** @class */ (function (_super) {
    __extends(LanguageService, _super);
    function LanguageService(http, userService, translateService, notifier) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.userService = userService;
        _this.translateService = translateService;
        _this.notifier = notifier;
        _this.languageChange = new EventEmitter();
        _this.currentLanguage = Language.de;
        if (localStorage.getItem(LanguageService.USER_LANGUAGE_STORAGE_KEY)) {
            _this.currentLanguage = Language[localStorage.getItem(LanguageService.USER_LANGUAGE_STORAGE_KEY)];
            _this.translateService.use(_this.currentLanguage);
        }
        return _this;
    }
    LanguageService.prototype.setCurrentLanguage = function (language) {
        var _this = this;
        if (language) {
            this.currentLanguage = language;
            localStorage.setItem(LanguageService.USER_LANGUAGE_STORAGE_KEY, language.toString());
            this.translateService.use(language).then(function () {
                _this.languageChange.emit();
            });
        }
    };
    LanguageService.prototype.getCurrentLanguage = function () {
        return this.currentLanguage;
    };
    LanguageService.prototype.selectLanguage = function (language) {
        var _this = this;
        if (this.userService.isUserActive()) {
            return this.updateLanguage(language.toString()).pipe(map(function (response) {
                var updateLanguageResponse = response;
                _this.setCurrentLanguage(updateLanguageResponse.language);
                return _this.currentLanguage.toString();
            }), catchError(function (error) {
                _this.notifier.showError('Failed to update user language.');
                return _this.currentLanguage.toString();
            }));
        }
        else {
            this.setCurrentLanguage(language);
            return of(language.toString());
        }
    };
    LanguageService.prototype.updateLanguage = function (language) {
        return this.http.post(LanguageService.API_URL, new UpdateLanguageRequest(language), { headers: this.buildRequestHeaders() });
    };
    LanguageService.USER_LANGUAGE_STORAGE_KEY = 'enjoy.user.language';
    LanguageService.API_URL = 'api/profile/language';
    return LanguageService;
}(AbstractService));
export { LanguageService };
