/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../menu/menu.component.ngfactory";
import * as i3 from "../menu/menu.component";
import * as i4 from "@angular/router";
import * as i5 from "../shared/services/user.service";
import * as i6 from "../shared/services/translate.service";
import * as i7 from "../components/notifier/notifier";
import * as i8 from "../shared/services/language.service";
import * as i9 from "@angular/common";
import * as i10 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Enjoy logo"], ["src", "assets/enjoy_logo.png"]], null, null, null, null, null))], null, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "header__menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-menu", [], null, [["window", "click"]], function (_v, en, $event) { var ad = true; if (("window:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MenuComponent_0, i2.RenderType_MenuComponent)), i1.ɵdid(3, 49152, null, 0, i3.MenuComponent, [i4.Router, i5.UserService, i6.TranslateService, i7.Notifier, i8.LanguageService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "header__lang"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "header__logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "Roche logo"], ["src", "assets/Roche3.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isEpassView; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i10.HeaderComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i10.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
