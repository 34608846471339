import {Directive, OnDestroy, OnInit} from '@angular/core';
import {MatButton} from '@angular/material';
import {Subscription} from 'rxjs';
import {ProgressService} from 'src/app/shared/services/progress.service';

@Directive({
  selector: 'button[appPreventResubmit]'
})
export class PreventResubmitDirective implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(private progressService: ProgressService, private button: MatButton) {}

  ngOnInit() {
    const originalDisabled = this.button.disabled;
    this.subscription = this.progressService.inFlight.subscribe((isStarted) => {
      if (isStarted) {
        this.button.disabled = true;
      } else {
        this.button.disabled = originalDisabled;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
