import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Notifier} from 'src/app/components/notifier/notifier';
import {LanguageService} from 'src/app/shared/services/language.service';
import {TranslateService} from 'src/app/shared/services/translate.service';
import {NonRocheEmailValidator} from 'src/app/validators/non-roche-email.validator';

import {RedirectService} from '../shared/services/redirect.service';
import {LoginService} from './login.service';
import {EnjoyErrorStateMatcher} from '../validators/enjoy-error-state.matcher';

@Component({
  selector: 'app-login-form',
  templateUrl: 'login-form.component.html',
  styleUrls: ['login-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginFormComponent implements OnInit {
  private readonly METATITLE_KEY = 'METATITLE';
  private readonly REGISTER_PATH = '/register/init';
  private readonly FORGOT_PASSWORD_PATH = '/password/forgot-password';

  loginForm: FormGroup;
  matcher = new EnjoyErrorStateMatcher();
  invalid_error: boolean = null;

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private router: Router,
    private loginService: LoginService,
    private redirectService: RedirectService,
    private notifier: Notifier) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, NonRocheEmailValidator.validate]),
      password: new FormControl('', [Validators.required])
    });

    this.setMetaTitle();
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
      const password = this.loginForm.value.password;
      const email = this.loginForm.value.email;
      this.loginService.login(email, password).subscribe(loginResponse => {
        this.router.navigate(['/']);
      }, error => {
        if (error.error.message === 'LOGIN_ERROR_TEXT') {
          this.loginForm.get('password').setErrors({'invalid': true});
          this.invalid_error = true;
        } else {
          this.notifier.showError(this.translateService.translate(error.error.message), true);
        }
      });
  }

  onForgotPassword() {
    let queryParams;
    if (this.loginForm.value.email && !this.loginForm.get('email').invalid) {
      queryParams = {queryParams: {email: this.loginForm.value.email}};
    }

    this.router.navigate([this.FORGOT_PASSWORD_PATH], queryParams);
  }

  onRegister() {
    this.router.navigate([this.REGISTER_PATH]);
  }

  onKeyUp() {
    if (this.invalid_error) {
      Object.keys(this.loginForm.controls).forEach(field => {
        if (field === 'password')  {
           const control = this.loginForm.get(field);
           control.setErrors(null);
           this.invalid_error = false;
        }
      });
    }
  }

  private setMetaTitle() {
    this.translateService.setTitle(this.METATITLE_KEY);
    this.languageService.languageChange.subscribe(() => {
      this.translateService.setTitle(this.METATITLE_KEY);
    });
  }
}
