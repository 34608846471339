import { Component, OnInit } from '@angular/core';
import {TranslateService} from '../../shared/services/translate.service';
import {LanguageService} from '../../shared/services/language.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  private readonly METATITLE_KEY = 'FAQ_METATITLE';

  constructor( private translateService: TranslateService,
               private languageService: LanguageService) { }

  ngOnInit() {
    this.setMetaTitle();
  }

  private setMetaTitle() {
    this.translateService.setTitle(this.METATITLE_KEY);
    this.languageService.languageChange.subscribe(() => {
      this.translateService.setTitle(this.METATITLE_KEY);
    });
  }

}
