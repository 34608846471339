import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {MaterialModule} from '../material.module';
import {SharedModule} from '../shared.module';
import {RedirectService} from '../shared/services/redirect.service';
import {RegistrationPasswordService} from '../shared/services/registration-password.service';
import {LoginFormComponent} from './login-form.component';
import {LoginService} from './login.service';

export const routes: Routes = [
  {path: '', component: LoginFormComponent}
];

@NgModule({
  declarations: [
    LoginFormComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    SharedModule
  ],
  exports: [
    LoginFormComponent
  ],
  providers: [
    LoginService,
    RedirectService,
    RegistrationPasswordService
  ],
  entryComponents: [
  ]
})
export class LoginModule {
}
