<main class="main">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="main__subtitle">{{'FAQ_SUBTITLE' | translate}}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>{{ 'FAQ_QUESTION_1' | translate }}</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div [innerHTML]="'FAQ_ANSWER_1' | translate"></div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>{{ 'FAQ_QUESTION_2' | translate }}</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>
            {{ 'FAQ_ANSWER_2' | translate }}
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>{{ 'FAQ_QUESTION_3' | translate }}</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>
            {{ 'FAQ_ANSWER_3' | translate }}
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>{{ 'FAQ_QUESTION_4' | translate }}</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>
            {{ 'FAQ_ANSWER_4' | translate }}
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>{{ 'FAQ_QUESTION_5' | translate }}</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>
            {{ 'FAQ_ANSWER_5' | translate }}
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>{{ 'FAQ_QUESTION_6' | translate }}</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>
            {{ 'FAQ_ANSWER_6' | translate }}
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>{{ 'FAQ_QUESTION_7' | translate }}</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>
            {{ 'FAQ_ANSWER_7' | translate }}
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</main>
