import 'hammerjs';

import {Component} from '@angular/core';

import {ProgressService} from './shared/services/progress.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Enjoy';
  showProgressBar = false;

  constructor(private progressService: ProgressService) {
    this.progressService.inFlight.subscribe((isStarted) => {
      setTimeout(() => {
        this.showProgressBar = isStarted;
      });
    });
  }
}
