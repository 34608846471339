import {APP_BASE_HREF, DOCUMENT} from '@angular/common';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class BasePathInterceptor implements HttpInterceptor {
  private basePath: string;

  constructor(@Inject(APP_BASE_HREF) private baseHref: string, @Inject(DOCUMENT) private document: any) {
    this.basePath = `${document.location.protocol}//${document.location.hostname}:${document.location.port}${baseHref}`;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiReq = req.clone({url: `${this.basePath}${req.url}`});
    return next.handle(apiReq);
  }
}
