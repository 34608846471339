import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AbstractService} from './abstract-service';
import {Language} from './language.service';

export class IVerifyTokenResponse {
  email: string;
  language: Language;
  privacyNoticeAndLegalStatementConfirmationRequired: boolean;
}

@Injectable()
export class RegistrationPasswordService extends AbstractService {
  readonly API_URL = 'api/register';

  constructor(private http: HttpClient) {
    super();
  }

  public verifyToken(code: string): Observable<IVerifyTokenResponse> {
    return this.http.get(`${this.API_URL}/verify-token`, {
      params: {
        code: code
      }
    }).pipe(map((response) => response as IVerifyTokenResponse));
  }

  public savePassword(email: string, password: string, code: string) {
    return this.http.post(`${this.API_URL}/password`,
      {email: email, password: password, token: code},
      {headers: this.buildRequestHeaders()});
  }
}
