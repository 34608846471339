import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ProgressService {
  inFlight = new BehaviorSubject<boolean>(false);

  public start() {
    this.inFlight.next(true);
  }

  public end() {
    this.inFlight.next(false);
  }
}
