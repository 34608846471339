import {formatDate} from '@angular/common';
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import * as html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import {LanguageService} from 'src/app/shared/services/language.service';
import {SettingsService} from 'src/app/shared/services/settings.service';

import {TranslateService} from '../../shared/services/translate.service';
import {UserService} from '../../shared/services/user.service';

@Component({
  selector: 'app-epass',
  templateUrl: 'epass.component.html',
  styleUrls: ['epass.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EPassComponent implements OnInit {

  @ViewChild('logotoEpass')
  logo: ElementRef;

  private readonly METATITLE_KEY = 'EPASS_METATITLE';
  public title: string;
  public firstName: string;
  public lastName: string;
  public personalNumber: string;
  public email: string;
  public photoSource: string;
  public accountExpiry: string;
  public userType: string;
  public epassExpirationInDays: number;

  constructor(private userService: UserService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private settingsService: SettingsService) {
  }

  ngOnInit() {
    this.userService.getLoggedUser().subscribe(loggedUser => {
      this.title = loggedUser.title;
      this.firstName = loggedUser.firstName;
      this.lastName = loggedUser.lastName;
      this.personalNumber = loggedUser.personalNumber;
      this.email = loggedUser.email;
      if (loggedUser.photo != null) {
        this.photoSource = 'data:image/png;base64,' + loggedUser.photo;
      }
      this.accountExpiry = loggedUser.accountExpiry;
      this.userType = loggedUser.userType;
    });

    this.settingsService.getSettings().subscribe((settings) => {
      this.epassExpirationInDays = settings.offlineExpiryPeriodDays;
    });

    this.setMetaTitle();
  }

  downloadPDF() {
    this.prepareEPassPdf(this.epassExpirationInDays);
  }

  private prepareEPassPdf(offlineExpiryDays: number) {
    const doc = new jsPDF('p', 'mm', 'a4');
    const self = this;
    const rocheLogo = this.logo.nativeElement;
    html2canvas(document.getElementById('epass'), {
      logging: true,
      scale: 2
    }).then(function (canvas) {
      const img = canvas.toDataURL('image/png');
      doc.addImage(img, 'JPEG', 50, 20, 110, 140);
      doc.addImage(rocheLogo, 'PNG', 140, 10, 20, 10);
      doc.setTextColor(100);
      doc.setFontSize(11).text(self.prepareText(offlineExpiryDays), 70, 170);
      // doc.save(self.prepareName());
      if (!navigator.userAgent.match('CriOS')) {
        doc.save(self.prepareName());
      } else {
        const enInfo = `
          <p style="text-align:center; margin-top: 40px; font-size: 24px">
            Google Chrome does not support downloading PDF files on the iOS. Please use Safari web browser.
          </p>`;
        const deInfo = `
          <p style="text-align:center; margin-top: 40px; font-size: 24px">
            Google Chrome unterstützt das Herunterladen von PDF-Dateien unter iOS nicht. Bitte benutzen Sie den Safari-Browser.
          </p>`;

        const x = window.open();
        x.document.open();
        x.document.write(enInfo);
        x.document.write(deInfo);
        x.document.close();
      }
    });
  }

  private prepareText(offlineExpiryDays: number): string {
    const d = new Date();
    d.setDate(d.getDate() + offlineExpiryDays);
    return this.translateService.translate('EXPIRATION_TEXT') + formatDate(d, 'dd-MMM-yyyy', 'en');
  }

  private prepareName(): string {
    return 'ePass_' + this.firstName + '_' + formatDate(new Date(), 'ddMMyy', 'en') + '.pdf';
  }

  private setMetaTitle() {
    this.translateService.setTitle(this.METATITLE_KEY);
    this.languageService.languageChange.subscribe(() => {
      this.translateService.setTitle(this.METATITLE_KEY);
    });
  }
}
