import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {Notifier} from '../../components/notifier/notifier';
import {AbstractService} from './abstract-service';
import {TranslateService} from './translate.service';
import {UserService} from './user.service';

export enum Language {
  de = 'de',
  en = 'en'
}

class UpdateLanguageRequest {
  language: string;

  constructor(language: string) {
    this.language = language;
  }
}

interface UpdateLanguageResponse {
  language: Language;
}

@Injectable()
export class LanguageService extends AbstractService {

  static USER_LANGUAGE_STORAGE_KEY = 'enjoy.user.language';
  static API_URL = 'api/profile/language';

  @Output()
  public languageChange: EventEmitter<any> = new EventEmitter<any>();

  private currentLanguage: Language = Language.de;

  constructor(private http: HttpClient,
    private userService: UserService,
    private translateService: TranslateService,
    private notifier: Notifier) {
    super();
    if (localStorage.getItem(LanguageService.USER_LANGUAGE_STORAGE_KEY)) {
      this.currentLanguage = Language[localStorage.getItem(LanguageService.USER_LANGUAGE_STORAGE_KEY)];
      this.translateService.use(this.currentLanguage);
    }
  }

  setCurrentLanguage(language: Language) {
    if (language) {
      this.currentLanguage = language;
      localStorage.setItem(LanguageService.USER_LANGUAGE_STORAGE_KEY, language.toString());
      this.translateService.use(language).then(() => {
        this.languageChange.emit();
      });
    }
  }

  getCurrentLanguage(): Language {
    return this.currentLanguage;
  }

  selectLanguage(language: Language): Observable<string> {
    if (this.userService.isUserActive()) {
      return this.updateLanguage(language.toString()).pipe(
        map(response => {
          const updateLanguageResponse = response as UpdateLanguageResponse;
          this.setCurrentLanguage(updateLanguageResponse.language);
          return this.currentLanguage.toString();
        }),
        catchError((error) => {
          this.notifier.showError('Failed to update user language.');
          return this.currentLanguage.toString();
        })
      );
    } else {
      this.setCurrentLanguage(language);
      return of(language.toString());
    }
  }

  updateLanguage(language: string): Observable<any> {
    return this.http.post(LanguageService.API_URL, new UpdateLanguageRequest(language), {headers: this.buildRequestHeaders()});
  }
}
