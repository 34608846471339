var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
export var UserType;
(function (UserType) {
    UserType["EMPLOYEE"] = "EMPLOYEE";
    UserType["PENSIONER"] = "PENSIONER";
})(UserType || (UserType = {}));
var LoggedUser = /** @class */ (function () {
    function LoggedUser(loggedUser) {
        Object.assign(this, loggedUser);
    }
    LoggedUser.fromJson = function (loggedUser) {
        return new LoggedUser(__assign({}, loggedUser));
    };
    LoggedUser.prototype.fullname = function () {
        return this.title + " " + this.firstName + " " + this.lastName;
    };
    return LoggedUser;
}());
export { LoggedUser };
