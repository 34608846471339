import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {EMPTY} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {LanguageService} from './shared/services/language.service';
import {UserService} from './shared/services/user.service';

const EPASS_URL = '/epass';
const ERROR_URL = '/error';

@Component({
  selector: 'app-home',
  template: ''
})
export class HomeComponent implements OnInit {

  constructor(private userService: UserService,
    private router: Router,
    private languageService: LanguageService) {
  }

  ngOnInit() {
    this.userService.getLoggedUser().pipe(
      map(loggedUser => {
        if (loggedUser.isActive) {
          this.languageService.setCurrentLanguage(loggedUser.language);
          this.router.navigate([EPASS_URL]);
        }
      }),
      catchError(() => {
        this.router.navigate([ERROR_URL, 'GENERAL_ERROR_MESSAGE']);
        return EMPTY;
      })
    ).subscribe();
  }
}
