import {Language} from '../shared/services/language.service';

export enum UserType {
  EMPLOYEE = 'EMPLOYEE',
  PENSIONER = 'PENSIONER'
}

export interface ILoggedUser {
  personalNumber: string;
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  language: Language;
  canRegister: boolean;
  isActive: boolean;
  photo: string;
  accountExpiry: string;
  userType: UserType;
}

export class LoggedUser implements ILoggedUser {
  personalNumber: string;
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  language: Language;
  canRegister: boolean;
  isActive: boolean;
  photo: string;
  accountExpiry: string;
  userType: UserType;

  constructor(loggedUser?: ILoggedUser) {
    Object.assign(this, loggedUser);
  }

  static fromJson(loggedUser: ILoggedUser): LoggedUser {
    return new LoggedUser({...loggedUser});
  }

  fullname(): string {
    return `${this.title} ${this.firstName} ${this.lastName}`;
  }
}
