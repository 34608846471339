<main class="main">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="main__subtitle">{{'ERROR_PAGE_SUBTITLE' | translate}}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 flex">
        <div class="error">
          <p class="error-message" [innerHTML]="message | translate">
          </p>
        </div>
      </div>
      <div class="col-12 flex">
        <div class="link"><a *ngIf="isUserLoggedIn && isEligible" (click)="register($event)">{{'REGISTER' | translate}}</a></div>
      </div>
      <div class="col-12 flex">
        <div class="link"><a *ngIf="isUserLoggedIn" (click)="backToLogin($event)">{{'LOGIN_AS_DIFFERENT_USER' |
            translate}}</a></div>
      </div>
      <div class="col-12 flex">
        <div class="link"><a *ngIf="!isUserLoggedIn" (click)="backToLogin($event)">{{'BACK_TO_LOGIN' | translate}}</a></div>
      </div>
    </div>
  </div>
</main>
