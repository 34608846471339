import {Component, ElementRef, HostListener, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

import {Notifier} from '../components/notifier/notifier';
import {Language, LanguageService} from '../shared/services/language.service';
import {TranslateService} from '../shared/services/translate.service';
import {UserService} from '../shared/services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent {
  @ViewChild('appMenu')
  menu: ElementRef;

  private itemLocationDisplayMap: Map<string, string[]> = new Map<string, string[]>();

  @HostListener('window:click', ['$event.target'])
  onClick(target) {
    if (!(target.classList.contains('menu__hamburger--button') || target.classList.contains('menu__hamburger--item'))
      && this.menu.nativeElement.classList.contains('opened')) {
      this.hideMenu();
    }
  }

  constructor(private route: Router,
    private userService: UserService,
    private translateService: TranslateService,
    private notifier: Notifier,
    private languageService: LanguageService) {
    this.itemLocationDisplayMap.set('logout', ['/epass', '/profile', '/update-profile', '/faq']);
    this.itemLocationDisplayMap.set('epass', ['/profile', '/update-profile', '/faq']);
    this.itemLocationDisplayMap.set('update_profile', ['/epass', '/faq']);
    this.itemLocationDisplayMap.set('faq',
      ['/epass', '/update-profile', '/login', '/register/init', '/register/final', '/password/verify-email',
        '/password/forgot-password', '/password/forgot-password/verify-email']);
  }

  openMenu() {
    this.menu.nativeElement.classList.add('opened');
  }

  hideMenu() {
    this.menu.nativeElement.classList.remove('opened');
  }

  showItem(item: string): boolean {
    if (!this.itemLocationDisplayMap.get(item)) {
      return true;
    }
    const currentLocation = this.route.url.toLocaleLowerCase().split('?')[0];
    if (currentLocation === '/faq') {
      if (this.userService.isUserLoggedIn()) {
        return this.itemLocationDisplayMap.get(item).includes(currentLocation);
      } else {
        return false;
      }
    } else {
      return this.itemLocationDisplayMap.get(item).includes(currentLocation);
    }
  }

  goToWebsite() {
    this.hideMenu();
    window.open(this.translateService.translate('WEBSITE'), '_blank');
  }

  goToGsite() {
    this.hideMenu();
    window.open(this.translateService.translate('GSITE'), '_blank');
  }

  showPrivacyPolicy() {
    this.hideMenu();
    window.open(this.translateService.translate('PRIVACY_NOTICE_URL'), '_blank');
  }

  contactSupport() {
    this.hideMenu();
    this.notifier.showSuccess(this.translateService.translate('CONTACT_SUPPORT_MESSAGE'), true);
  }

  faq() {
    this.hideMenu();
    this.route.navigate(['/faq']);
  }

  goToLogin() {
    this.hideMenu();
    this.route.navigate(['/login']);
  }

  goToEPass() {
    this.hideMenu();
    this.route.navigate(['/epass']);
  }

  goToUpdateProfile() {
    this.hideMenu();
    this.route.navigate(['/update-profile']);
  }

  logout() {
    this.hideMenu();
    this.userService.logout().subscribe(() => this.route.navigate(['/login']));
  }

  toggleLanguage() {
    this.languageService.selectLanguage(this.language).subscribe();
  }

  get language(): Language {
    return this.languageService.getCurrentLanguage() === Language.de ? Language.en : Language.de;
  }
}
