var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { EMPTY, of, throwError } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { LoggedUser } from '../../models/logged-user';
import { AbstractService } from './abstract-service';
var UserService = /** @class */ (function (_super) {
    __extends(UserService, _super);
    function UserService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.API_URL = 'api/logged-user';
        return _this;
    }
    UserService.prototype.getLoggedUser = function (opts) {
        if (this.loggedUser) {
            return of(this.loggedUser);
        }
        else if (this.observable) {
            return this.observable;
        }
        else {
            if (opts && opts.dontFetch) {
                return throwError('not fetched.');
            }
            this.observable = this.fetchUserProfile();
            return this.observable;
        }
    };
    UserService.prototype.logout = function () {
        if (this.isUserLoggedIn()) {
            this.loggedUser = null;
            return this.http.post(this.API_URL + '/logout', '');
        }
        else {
            return of(true);
        }
    };
    UserService.prototype.forceLogout = function () {
        this.loggedUser = null;
        return this.http.post(this.API_URL + '/logout', '').pipe(map(function (response) {
            return true;
        }), catchError(function (err) {
            return of(true);
        }));
    };
    UserService.prototype.isUserLoggedIn = function () {
        return this.loggedUser ? true : false;
    };
    UserService.prototype.isUserActive = function () {
        return this.loggedUser && this.loggedUser.isActive;
    };
    UserService.prototype.fetchUserProfile = function () {
        var _this = this;
        return this.http.get(this.API_URL)
            .pipe(map(function (response) {
            _this.observable = null;
            return response;
        }), map(function (loggedUserJson) {
            _this.loggedUser = LoggedUser.fromJson(loggedUserJson);
            return _this.loggedUser;
        }), catchError(function (err) {
            console.error(err);
            _this.observable = null;
            return EMPTY;
        }), share());
    };
    return UserService;
}(AbstractService));
export { UserService };
