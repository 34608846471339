var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AbstractService } from 'src/app/shared/services/abstract-service';
var LoginService = /** @class */ (function (_super) {
    __extends(LoginService, _super);
    function LoginService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.API_URL = 'auth';
        return _this;
    }
    LoginService.prototype.login = function (email, password) {
        return this.http.post(this.API_URL, { username: email, password: password }, { headers: this.buildRequestHeaders() })
            .pipe(map(function (response) { return response; }));
    };
    return LoginService;
}(AbstractService));
export { LoginService };
