import {Injectable} from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
  SimpleSnackBar,
} from '@angular/material';
import {NavigationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

import {NotifierComponent, NotifierComponentData} from './notifier.component';

export const SUCCESS = 'success';
export const WARN = 'warn';
export const ERROR = 'error';


@Injectable()
export class Notifier {
  public static snackBarFlashDuration = 7000;
  public static snackBarVerticalPosition = 'top' as MatSnackBarVerticalPosition;
  public snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  public message: string;
  public action: string;

  constructor(private snackBar: MatSnackBar, private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (this.snackBarRef) {
        this.dismiss();
      }
    });
  }

  private static getSnackBarConfig(message: string, action: string = null, type: string, userClose?: boolean): MatSnackBarConfig {
    let panelClass;

    switch (type) {
      case ERROR:
        panelClass = ['error'];
        break;

      case SUCCESS:
        panelClass = ['success'];
        break;

      case WARN:
        panelClass = ['warn'];
        break;
    }

    return {
      duration: userClose ? -1 : this.snackBarFlashDuration,
      verticalPosition: this.snackBarVerticalPosition,
      data: new NotifierComponentData(message, action),
      panelClass: panelClass
    };
  }

  public show(message: string, type: string = SUCCESS, userClose = false) {
    this.snackBarRef = this.snackBar.openFromComponent(NotifierComponent, Notifier.getSnackBarConfig(message, 'x', type, userClose));
  }

  public showCustom(message: string, action: string, config: MatSnackBarConfig) {
    this.snackBarRef = this.snackBar.open(message, action, config);
  }

  public showSuccess(message: string, userClose?: boolean) {
    this.show(message, SUCCESS, userClose);
  }

  public showWarn(message: string, userClose?: boolean) {
    this.show(message, WARN, userClose);
  }

  public showError(message: string, userClose?: boolean) {
    this.show(message, ERROR, userClose);
  }

  public onAction(): Observable<void> {
    return this.snackBarRef.onAction();
  }

  public dismiss() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }
}
