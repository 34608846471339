import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {ProgressService} from 'src/app/shared/services/progress.service';

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {
  constructor(private progressService: ProgressService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.progressService.start();
    return next.handle(req).pipe(
      map((event) => {
        this.progressService.start();
        return event;
      }),
      catchError(error => {
        return throwError(error);
      }),
      finalize(() => {
        this.progressService.end();
      })
    );
  }
}
