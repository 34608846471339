import {Location} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable()
export class RedirectService {
  private LOGIN_PATH = '/login';
  private FORGOT_PASSWORD_PATH = '/password/forgot-password';

  constructor(private location: Location) {}

  public redirectToLogin(email?: string) {
    const appPath = this.getAppPath();
    window.location.href = appPath + this.LOGIN_PATH + (email ? '?email=' + email : '');
  }

  private getAppPath(): string {
    const angularRoute = this.location.path();
    const url = window.location.href;
    return url.replace(angularRoute, '');
  }
}
