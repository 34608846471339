import {NgModule} from '@angular/core';

import {MaterialModule} from '../material.module';
import {MenuModule} from '../menu/menu.module';
import {HeaderComponent} from './header.component';

@NgModule({
    declarations: [
        HeaderComponent
    ],
    imports: [
        MaterialModule,
        MenuModule
    ],
    exports: [
        HeaderComponent,
    ],
    providers: [
    ],
    entryComponents: [
    ]
})
export class HeaderModule {
}
