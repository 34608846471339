import { NgModule } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDatepickerModule,
  MatNativeDateModule
} from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';

const ENJOY_DATE_FORMATS = {
  parse: {
    dateInput: '',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'DD-MMM-YYYY',
    dateA11yLabel: 'DD-MMM-YYYY',
    monthYearA11yLabel: 'DD-MMM-YYYY',
  }
};

@NgModule({
  declarations: [],
  imports: [MatDatepickerModule],
  exports: [MatDatepickerModule, MatNativeDateModule],
  providers: [
    {
      provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: ENJOY_DATE_FORMATS
    }
  ]
})
export class DatePickerModule { }
