import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractService} from 'src/app/shared/services/abstract-service';

import {LoginResponse} from './login-response';

@Injectable()
export class LoginService extends AbstractService {
    readonly API_URL = 'auth';

    constructor(private http: HttpClient) {
        super();
    }

    public login(email: string, password: string): Observable<LoginResponse> {
        return this.http.post(this.API_URL, {username: email, password: password}, {headers: this.buildRequestHeaders()})
            .pipe(
                map(response => response as LoginResponse)
            );
    }
}
