import {Validators, FormControl} from '@angular/forms';

export class NonRocheEmailValidator {

  static validate(fc: FormControl) {
    const value = fc.value;

    if ((/^.+@.*roche\.com$/g.test(value))) {
      return {not_private: true};
    }
  }
}
