<main class="main main--email">
  <div class="container">
    <div class="row">
      <div class="col-12 flex">
        <form class="main__login-form" novalidate [formGroup]="loginForm">
          <mat-form-field>
            <input #email type="email" matInput placeholder="{{'EMAIL_FIELD' | translate}}" formControlName="email" (keyup)="onKeyUp()" [errorStateMatcher]="matcher">
            <mat-error *ngIf="loginForm.get('email').hasError('required')">{{'REQUIRED_FIELD' | translate}}</mat-error>
            <mat-error *ngIf="loginForm.get('email').hasError('not_private')">{{'REGISTER_STEP2_USE_PRIVATE_EMAIL' | translate}}</mat-error>
            <mat-error *ngIf="!loginForm.get('email').hasError('required') && !loginForm.get('email').hasError('not_private') && loginForm.get('email').invalid">{{'INVALID_EMAIL' | translate}}</mat-error>
          </mat-form-field>
          <mat-form-field class="password">
            <input #password type="password" matInput placeholder="{{'PASSWORD_FIELD' | translate}}"  formControlName="password" maxlength="30" [errorStateMatcher]="matcher"/>
            <mat-error *ngIf="loginForm.get('password').hasError('required')">{{'REQUIRED_FIELD' | translate}}</mat-error>
            <mat-error *ngIf="!loginForm.get('password').hasError('required') && loginForm.get('password').invalid">{{'LOGIN_ERROR_TEXT' | translate}}</mat-error>
          </mat-form-field>
          <button mat-raised-button class="login-button" (click)="onSubmit()" appPreventResubmit>{{'LOGIN_BUTTON' | translate}}</button>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12 flex">
        <form class="main__login-form" novalidate>
          <button mat-button class="blue" (click)="onForgotPassword()">{{'FORGOT_PASSWORD_LINK' | translate}}</button>
          <button mat-button class="blue register" (click)="onRegister()">{{'REGISTER' | translate}}</button>
        </form>
      </div>
    </div>
  </div>
</main>
