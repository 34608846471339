import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError, map, share} from 'rxjs/operators';

import {ILoggedUser, LoggedUser} from '../../models/logged-user';
import {AbstractService} from './abstract-service';

@Injectable()
export class UserService extends AbstractService {
  readonly API_URL = 'api/logged-user';

  private loggedUser: LoggedUser;
  private observable: Observable<LoggedUser>;

  constructor(private http: HttpClient) {
    super();
  }

  getLoggedUser(opts?: {dontFetch?: boolean}): Observable<LoggedUser> {
    if (this.loggedUser) {
      return of(this.loggedUser);
    } else if (this.observable) {
      return this.observable;
    } else {
      if (opts && opts.dontFetch) {
        return throwError('not fetched.');
      }
      this.observable = this.fetchUserProfile();
      return this.observable;
    }
  }

  logout(): Observable<any> {
    if (this.isUserLoggedIn()) {
      this.loggedUser = null;
      return this.http.post(this.API_URL + '/logout', '');
    } else {
      return of(true);
    }
  }

  forceLogout(): Observable<any> {
    this.loggedUser = null;
    return this.http.post(this.API_URL + '/logout', '').pipe(
      map(response => {
        return true;
      }),
      catchError((err) => {
        return of(true);
      })
    );
  }

  isUserLoggedIn(): boolean {
    return this.loggedUser ? true : false;
  }

  isUserActive(): boolean {
    return this.loggedUser && this.loggedUser.isActive;
  }

  private fetchUserProfile(): Observable<LoggedUser> {
    return this.http.get(this.API_URL)
      .pipe(
        map(response => {
          this.observable = null;
          return response as ILoggedUser;
        }),
        map(
          loggedUserJson => {
            this.loggedUser = LoggedUser.fromJson(loggedUserJson);
            return this.loggedUser;
          }),
        catchError((err) => {
          console.error(err);
          this.observable = null;
          return EMPTY;
        }),
        share()
      );
  }
}
