import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {Language} from './language.service';

@Injectable()
export class TranslateService {
  data: any = {};

  constructor(private http: HttpClient, private title: Title) {
  }

  use(lang: Language): Promise<any> {
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang.toString() || 'de'}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }

  translate(key: string): string {
    return this.data[key] || key;
  }

  setTitle(titleId: string) {
    this.title.setTitle(this.data[titleId] || titleId);
  }
}
