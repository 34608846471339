import {NgModule} from '@angular/core';

import {PersonComponent} from './components/person/person.component';
import {PreventResubmitDirective} from './components/prevent-resubmit/prevent-resubmit.directive';
import {MaterialModule} from './material.module';
import {TranslatePipe} from './pipes/translate.pipe';

@NgModule({
  declarations: [
    TranslatePipe,
    PersonComponent,
    PreventResubmitDirective
  ],
  imports: [
    MaterialModule
  ],
  providers: [],
  exports: [
    TranslatePipe,
    PersonComponent,
    PreventResubmitDirective
  ]
})
export class SharedModule {}
