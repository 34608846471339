<main class="main">
  <div class="epass-container" id="epass">
    <div class="row">
      <div class="col-12">
        <img #logotoEpass src="assets/Roche3.png" alt="" style="display:none">
        <img class="main__logo" src="assets/epass_logo.png" alt="Enjoy logo">
        <img class="main__member" src="assets/epass_member.png" alt="Epass member">
      </div>
    </div>
    <div class="row">
      <div class="col-12 epass-content">
        <div class="static-link">
          <mat-icon>chevron_right</mat-icon>
          <mat-icon>chevron_right</mat-icon>
          <span>enjoy.roche.ch</span>
        </div>
        <div class="epass-card">
          <div class="epass-card__left">
            <img *ngIf="photoSource" [src]="photoSource" />
          </div>
          <div class="epass-card__right">
            <h3>{{title | translate}} {{firstName}} {{lastName}}</h3>
          </div>
        </div>
        <div class="epass-info">
          <div class="info__pensioner" *ngIf="userType === 'PENSIONER'; else employee">
            <p>
              <span class="bold">
                Der Mitgliederausweis für Pensionierte berechtigt die Inhaberin / den Inhaber zu vergünstigtem
                Eintritt nach den vereinbarten Konditionen mit maximal einer Begleitperson. Der Pass ist nicht übertragbar.
              </span>
            </p>
            <p>
              The membership card for retired employees entitles the holder, and covers a maximum of
              one accompanying person, to discounted admission in accordance with the agreed
              conditions. The card is not transferable.
            </p>
          </div>
          <ng-template #employee>
            <div class="info__employee">
				<p>
					<span class="bold">
						Der Mitgliederausweis für Mitarbeitende berechtigt die Inhaberin / den Inhaber zu vergünstigtem
						Eintritt nach den vereinbarten Konditionen mit maximal einer Begleitperson und bis zu drei
						Kindern. Der Pass ist nicht übertragbar.
					</span>
				</p>
				<p>
					The membership card for employees entitles the holder, and covers a maximum of one accompanying
					person and up to three children, to discounted admission in accordance with the agreed conditions.
					The card is not transferable.
				</p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="container pdf-container">
    <div class="row">
      <div class="col-12">
        <div class="pdf">
          <button mat-button (click)="downloadPDF()">
            <mat-icon>picture_as_pdf</mat-icon>
            <span>
              {{'DOWNLOAD_BUTTON' | translate}}
              <span class="valid-hint">
                {{ ('VALID_HINT_1' | translate) + epassExpirationInDays + ('VALID_HINT_2' | translate)}}
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</main>
