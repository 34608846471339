var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MatSnackBarRef, SimpleSnackBar } from '@angular/material';
var NotifierComponentData = /** @class */ (function () {
    function NotifierComponentData(message, action) {
        if (action === void 0) { action = ''; }
        this.message = message;
        this.action = action;
    }
    return NotifierComponentData;
}());
export { NotifierComponentData };
var NotifierComponent = /** @class */ (function (_super) {
    __extends(NotifierComponent, _super);
    function NotifierComponent(snackBarRef, data) {
        var _this = _super.call(this, snackBarRef, data) || this;
        _this.snackBarRef = snackBarRef;
        if (data) {
            _this.message = data.message;
            _this.actionLabel = data.action;
        }
        return _this;
    }
    NotifierComponent.prototype.action = function () {
        this.snackBarRef.dismiss();
    };
    return NotifierComponent;
}(SimpleSnackBar));
export { NotifierComponent };
