import {NgModule} from '@angular/core';

import {MaterialModule} from '../material.module';
import {SharedModule} from '../shared.module';
import { FaqComponent } from './faq/faq.component';
import {RouterModule, Routes} from '@angular/router';
import {MenuComponent} from './menu.component';

export const routes: Routes = [
  {path: 'faq', component: FaqComponent}
];
@NgModule({
    declarations: [
        MenuComponent,
        FaqComponent
    ],
    imports: [
      RouterModule.forChild(routes),
        MaterialModule,
        SharedModule
    ],
    exports: [
        MenuComponent,
    ],
    providers: [
    ],
    entryComponents: [
    ]
})
export class MenuModule {
}
