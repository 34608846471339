<div class="menu">
  <div class="menu__hamburger">
    <div class="menu__hamburger--button" (click)="openMenu()">
      <span class="menu__hamburger--item"></span>
      <span class="menu__hamburger--item"></span>
      <span class="menu__hamburger--item"></span>
    </div>
  </div>
  <div class="sidemenu" #appMenu>
    <p class="menu-header" (click)="hideMenu()">Menu</p>
    <ul class="menu-items">
      <li class="menu-item" *ngIf="showItem('go_to_gsite')" (click)="goToGsite()">
        <mat-icon>launch</mat-icon>
        <span class="menu__item--label">{{'GO_TO_GSITE' | translate}}</span>
      </li>
      <li class="menu-item" *ngIf="showItem('epass')" (click)="goToEPass()">
        <mat-icon>account_box</mat-icon>
        <span class="menu__item--label">{{'YOUR_EPASS' | translate}}</span>
      </li>
      <li class="menu-item" *ngIf="showItem('update_profile')" (click)="goToUpdateProfile()">
        <mat-icon>face</mat-icon>
        <span class="menu__item--label">{{'UPDATE_YOUR_PROFILE' | translate}}</span>
      </li>
      <li class="menu-item" *ngIf="showItem('faq')" (click)="faq()">
        <mat-icon>live_help</mat-icon>
        <span class="menu__item--label">{{'FAQ' | translate}}</span>
      </li>
      <li class="menu-item" *ngIf="showItem('contact_support')" (click)="contactSupport()">
        <mat-icon>chat</mat-icon>
        <span class="menu__item--label">{{'CONTACT_SUPPORT' | translate}}</span>
      </li>
      <mat-divider></mat-divider>
      <li class="menu-item switcher" (click)="toggleLanguage()" *ngIf="language === 'de'">
        <div class="icon">
          DE
        </div>
        <span class="menu__item--label">{{'SWITCH_TO_GERMAN' | translate}}</span>
      </li>
      <li class="menu-item switcher" (click)="toggleLanguage()" *ngIf="language === 'en'">
        <div class="icon">
          EN
        </div>
        <span class="menu__item--label">{{'SWITCH_TO_ENGLISH' | translate}}</span>
      </li>
      <li class="menu-item logout" *ngIf="showItem('logout')" (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
        <span class="menu__item--label">{{'LOGOUT' | translate}}</span>
      </li>
      <li class="menu-item logout" *ngIf="!showItem('logout')" (click)="goToLogin()">
        <mat-icon>input</mat-icon>
        <span class="menu__item--label">{{'LOGIN' | translate}}</span>
      </li>
    </ul>
  </div>
</div>
