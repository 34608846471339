import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
  FORBIDDEN,
  GATEWAY_TIMEOUT,
  INTERNAL_SERVER_ERROR,
  METHOD_NOT_ALLOWED,
  NOT_FOUND,
  UNAUTHORIZED,
  UNPROCESSABLE_ENTITY,
} from 'http-status-codes';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {Notifier} from '../../components/notifier/notifier';
import {TranslateService} from '../../shared/services/translate.service';

export const VALIDATION_ERROR = 'Validation error';
const SKIP_REDIRECT_FOR_PATHS = ['api/logged-user/logout'];

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private router: Router, private notifier: Notifier, private translateService: TranslateService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === UNAUTHORIZED) {
            if (error.error.message === 'ACCESS_DENIED') {
              if (SKIP_REDIRECT_FOR_PATHS.includes(req.url)) {
                return throwError(error);
              }
              this.notifier.showError(this.translateService.translate(error.error.message));
              this.router.navigate(['/login']);
              return EMPTY;
            } else {
              return throwError(error);
            }
          } else {
            return this.handleError(error);
          }
        }
      })
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === UNPROCESSABLE_ENTITY) {
      if (error.error.message === VALIDATION_ERROR) {
        this.notifier.showError(this.retrieveMessages(error.error.validationErrors));
        return EMPTY;
      }
    } else if (error.status === INTERNAL_SERVER_ERROR || error.status === GATEWAY_TIMEOUT) {
      this.notifier.showError(this.translateService.translate('INTERNAL_SERVER_ERROR'));
      return EMPTY;
    } else if (error.status === NOT_FOUND || error.status === METHOD_NOT_ALLOWED) {
      this.notifier.showError(this.translateService.translate('NOT_FOUND'));
      return EMPTY;
    } else if (error.status === FORBIDDEN) {
      this.notifier.showError(this.translateService.translate('FORBIDDEN'));
      return EMPTY;
    }
    return throwError(error);
  }

  private retrieveMessages(validationErrors: any[]): string {
    const messageArray = validationErrors.map(error => this.translateService.translate(error.message));
    return messageArray.toString();
  }
}
