var ENJOY_DATE_FORMATS = {
    parse: {
        dateInput: '',
    },
    display: {
        dateInput: 'DD-MMM-YYYY',
        monthYearLabel: 'DD-MMM-YYYY',
        dateA11yLabel: 'DD-MMM-YYYY',
        monthYearA11yLabel: 'DD-MMM-YYYY',
    }
};
var ɵ0 = ENJOY_DATE_FORMATS;
var DatePickerModule = /** @class */ (function () {
    function DatePickerModule() {
    }
    return DatePickerModule;
}());
export { DatePickerModule };
export { ɵ0 };
