import {Location} from '@angular/common';
import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {UserService} from '../shared/services/user.service';

const REGISTRATION_URL = '/register';

@Component({
  selector: 'app-error-page',
  templateUrl: 'error.component.html',
  styleUrls: ['error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorComponent implements OnInit, OnDestroy {
  sub: Subscription;
  message: string;
  isUserLoggedIn: boolean;
  isEligible: boolean;

  constructor(private router: Router, private userService: UserService, private route: ActivatedRoute, private location: Location) {
  }

  ngOnInit() {
    this.message = 'GENERAL_ERROR_MESSAGE';
    this.sub = this.route.params.subscribe(params => {
      const message = params['message'];
      if (message) {
        this.message = message;
      }
      this.location.replaceState('/error');
    });
    this.isUserLoggedIn = this.userService.isUserLoggedIn();
    if (this.isUserLoggedIn) {
      this.userService.getLoggedUser().subscribe(
        (loggedUser) => this.isEligible = loggedUser.canRegister
      );
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  backToLogin($event) {
    $event.stopPropagation();
    if (this.isUserLoggedIn) {
      this.userService.logout().subscribe(() => this.router.navigate(['/login']));
    } else {
      this.router.navigate(['/login']);
    }
  }

  register($event) {
    this.router.navigate([REGISTRATION_URL]);
  }
}

