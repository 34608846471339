import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Notifier } from '../components/notifier/notifier';
import { Language, LanguageService } from '../shared/services/language.service';
import { TranslateService } from '../shared/services/translate.service';
import { UserService } from '../shared/services/user.service';
var MenuComponent = /** @class */ (function () {
    function MenuComponent(route, userService, translateService, notifier, languageService) {
        this.route = route;
        this.userService = userService;
        this.translateService = translateService;
        this.notifier = notifier;
        this.languageService = languageService;
        this.itemLocationDisplayMap = new Map();
        this.itemLocationDisplayMap.set('logout', ['/epass', '/profile', '/update-profile', '/faq']);
        this.itemLocationDisplayMap.set('epass', ['/profile', '/update-profile', '/faq']);
        this.itemLocationDisplayMap.set('update_profile', ['/epass', '/faq']);
        this.itemLocationDisplayMap.set('faq', ['/epass', '/update-profile', '/login', '/register/init', '/register/final', '/password/verify-email',
            '/password/forgot-password', '/password/forgot-password/verify-email']);
    }
    MenuComponent.prototype.onClick = function (target) {
        if (!(target.classList.contains('menu__hamburger--button') || target.classList.contains('menu__hamburger--item'))
            && this.menu.nativeElement.classList.contains('opened')) {
            this.hideMenu();
        }
    };
    MenuComponent.prototype.openMenu = function () {
        this.menu.nativeElement.classList.add('opened');
    };
    MenuComponent.prototype.hideMenu = function () {
        this.menu.nativeElement.classList.remove('opened');
    };
    MenuComponent.prototype.showItem = function (item) {
        if (!this.itemLocationDisplayMap.get(item)) {
            return true;
        }
        var currentLocation = this.route.url.toLocaleLowerCase().split('?')[0];
        if (currentLocation === '/faq') {
            if (this.userService.isUserLoggedIn()) {
                return this.itemLocationDisplayMap.get(item).includes(currentLocation);
            }
            else {
                return false;
            }
        }
        else {
            return this.itemLocationDisplayMap.get(item).includes(currentLocation);
        }
    };
    MenuComponent.prototype.goToWebsite = function () {
        this.hideMenu();
        window.open(this.translateService.translate('WEBSITE'), '_blank');
    };
    MenuComponent.prototype.goToGsite = function () {
        this.hideMenu();
        window.open(this.translateService.translate('GSITE'), '_blank');
    };
    MenuComponent.prototype.showPrivacyPolicy = function () {
        this.hideMenu();
        window.open(this.translateService.translate('PRIVACY_NOTICE_URL'), '_blank');
    };
    MenuComponent.prototype.contactSupport = function () {
        this.hideMenu();
        this.notifier.showSuccess(this.translateService.translate('CONTACT_SUPPORT_MESSAGE'), true);
    };
    MenuComponent.prototype.faq = function () {
        this.hideMenu();
        this.route.navigate(['/faq']);
    };
    MenuComponent.prototype.goToLogin = function () {
        this.hideMenu();
        this.route.navigate(['/login']);
    };
    MenuComponent.prototype.goToEPass = function () {
        this.hideMenu();
        this.route.navigate(['/epass']);
    };
    MenuComponent.prototype.goToUpdateProfile = function () {
        this.hideMenu();
        this.route.navigate(['/update-profile']);
    };
    MenuComponent.prototype.logout = function () {
        var _this = this;
        this.hideMenu();
        this.userService.logout().subscribe(function () { return _this.route.navigate(['/login']); });
    };
    MenuComponent.prototype.toggleLanguage = function () {
        this.languageService.selectLanguage(this.language).subscribe();
    };
    Object.defineProperty(MenuComponent.prototype, "language", {
        get: function () {
            return this.languageService.getCurrentLanguage() === Language.de ? Language.en : Language.de;
        },
        enumerable: true,
        configurable: true
    });
    return MenuComponent;
}());
export { MenuComponent };
